import React from 'react';
import '../css/style.css'

function Slider() {
  return (
    <div className="Slider" id="slider">
      <section className="slider_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="detail-box">
                <div className="desktopOnly">
                  <br />
                </div>                
                <h1>
                  No one wants to read the instruction manual.
                </h1>
                <h4>
                  <i>Let me do it for you!</i>
                </h4>
                <p>
                  My name is Sterling H. and I love putting those tedious and frustrating projects together that require time and attention to detail so let me take care of yours.
                </p>
                <p>
                  If you have a project to discuss please text me the details. Providing item codes or SKU’s will help a lot as I can give you a better quote if I know the details. Got something other than an assembly job? As long as it doesn’t involve getting on a roof I can probably take care of it for you. Text me at 816-807-0021 and I’ll get in touch with you very soon.
                </p>
                <p>
                  I would describe myself as an engineer, a carpenter, all-around handyman, and an assembler/installer. I also enjoy coming up with custom LED lighting and wireless solutions for the home.
                </p>
                {/* <h3 className= "mobileOnly" >
                  <center><i>NOW SERVICING:<br/>OVERLAND PARK AREA</i></center>
                  <br/>
                </h3> */}
                <a href="#contact" id='contactButton'>
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src="assets/images/ikea-manual.jpg" alt="" id="ikea"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Slider;
