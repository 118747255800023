import React from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../css/style.css'

function Contact() {
  // const [name, setName] = useState('');
  // const [phone, setPhone] = useState('');
  // const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_d5rz39l', 'contact_form', form.current, 'EFj9c5GG3jBEabb42')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    
    alert('Email submitted!');
  };

  // function onChange(e){
  //   resetForm();
  // }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   alert('A name was submitted: ' + name);
  //   resetForm();
  // }

  // function resetForm() {
  //   setName('');
  //   setPhone('');
  //   setEmail('');
  //   setMessage('');
  // }

  return (
    <>
      <center><hr id="mobileDivider"/></center>
      <div className="Contact" id="contact">
        <section className="contact_section layout_padding">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="heading_container">
                <h2>
                  Contact Us
                </h2>
              </div>
              <div id="formSection" >
                <form ref={form} onSubmit={sendEmail} >
                  <div className="form-group">
                    <label name="name" id="name" className="form-labels">Name:</label><br />
                    <input type="text" name="name" />
                  </div>

                  <div className="form-group">
                    <label name="phone" className="form-labels">Phone Number:</label><br />
                    <input type="text"  name="phone" />
                  </div>

                  <div className="form-group">
                    <label name="email" className="form-labels">Email:</label><br />
                    <input type="email" aria-describedby="emailHelp" name="email" />
                  </div>

                  <div className="form-group">
                    <label name="message" className="form-labels">Message:</label><br />
                    <textarea name="message"/>
                  </div>

                  <div className="d-flex ">
                    <button type="submit" className="btn btn-primary" value="Send" >
                      SEND
                    </button>
                  </div>
                </form>
                </div>
              </div>
              <div className="col-md-6">
                <div className="column">
                 <div id="contactLines">
                  <div className="row-md-6">
                    <div className="contactInfo">
                      <img src="assets/images/email.png" alt="email"/>
                      <a href = "mailto: aceassembler@gmail.com"><span className="email">aceassembler@gmail.com</span></a>
                    </div>
                  </div>

                  <div className="row-md-6">
                    <div className="contactInfo">
                      <img src="assets/images/phone.png" alt="phone"/>
                      <span className="email">816-807-0021</span>
                    </div>
                  </div>
                  </div>
                  <div className="row-md-6">
                    <div className="map_container" id="mapBox">
                      <iframe title="OverlandPark" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198683.76094611106!2d-94.70634720979876!3d38.91410500652087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c09551a72638d3%3A0x24ce4feb3844f9c8!2sOverland%20Park%2C%20KS!5e0!3m2!1sen!2sus!4v1704160788434!5m2!1sen!2sus" className="maps" loading="lazy" ></iframe>
                    </div>
                  </div>
                  
                </div>
              </div> 
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contact;
