import React from 'react'
import '../css/style.css'

function Header() {
  return(
    <header className="header_section" id="header">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg custom_nav-container ">
          <a className="navbar-brand" href="index.html">
            <span className = "Logo">
              <img src="assets/images/aceassemblerlogo.png" alt="" />
            </span>
          </a>

          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#slider">Home</a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="#about"> About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#service">Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">Contact Us</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
} 

export default Header;