import Contact from '../src/components/Contact'
import Footer from '../src/components/Footer'
import Header from '../src/components/Header'
import Photos from '../src/components/Photos'
import Service from '../src/components/Service'
import Slider from '../src/components/Slider'
import '../src/css/style.css'

function App() {
  return (
    <div>
    <Header />
    <Slider />
    <Photos />
    <Service />
    <Contact />
    <Footer />

    </div>
  );
}

export default App;