import React from 'react'
import '../css/style.css'

/*
.Footer {
  display: flex;
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/
function Footer() {
    return(
        <footer className="footer_section" id="footer">
        <div className="container">
          <p>
            &copy; <span id="displayDateYear">2024</span> Ace Assembler
          </p>
        </div>
      </footer>
    )
} 

export default Footer;