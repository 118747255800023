import React from 'react';
import '../css/style.css'

function Service() {
  return (
    <>
      <center><hr id="mobileDivider"/></center>
      <div className="Service" id="service">
        <section className="service_section layout_padding">
          <div className="container ">
            <div className="heading_container heading_center">
              <h2> Our Services </h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="img-box">
                  <img src="assets/images/professional-img.png" id="buddy" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col">
                  <div className="box ">
                    <div className="row">
                      <div className="col">
                        <div className="img-box">
                          <img src="assets/images/s2.png" alt="" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="detail-box">
                          <h5>
                            Electrical
                          </h5>
                          <p>
                            Let me design custom LED lighting and wireless solutions for your home.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="box ">
                    <div className="row">
                      <div className="col">
                        <div className="img-box">
                          <img src="assets/images/s1.png" alt="" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="detail-box">
                          <h5>
                            Assembly & Installation
                          </h5>
                          <p>
                            Don't let those manuals trip you up. I can do the assembling and installation. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="box ">
                    <div className="row">
                      <div className="col">
                        <div className="img-box">
                          <img src="assets/images/carpentry.png" alt="" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="detail-box">
                          <h5>
                            Carpentry & Handywork
                          </h5>
                          <p>
                            I can make furniture and design woodwork additions to your existing pieces.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
    </div>
    </>
  );
}

export default Service;