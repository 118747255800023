import React from 'react';
import Slider from "react-slick";
import '../css/style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Photos() {

  var settings = {
    autoPlay: true,
    autoplaySpeed: 3000,
    className: 'slideshow',
    dots: true,
    // dotsClass: 'dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    // variableWidth: '500px'
    // centerPadding: '10px',
  };

  return (
    <>
    <center><hr id="mobileDivider"/></center>
    <div className="Photos" id="photos">
      <section className="photos_section" id="photoScroll">
        <div className="container ">
          <h4>
            Below are pictures of the the most challenging assembly I’ve done: 
            </h4>
            <h5>
              <i>IKEA Pax/Komplement product line of organizers</i>
          </h5>
          <Slider {...settings}>
            <div className='carousel'>
              <img src="assets/images/Doors-Stacked-Middle.jpeg" alt="Doors Stacked Middle"/>
              <h5>Doors Stacked Middle</h5>
            </div>
            <div className='carousel'>
              <img src ="assets/images/Doors-Spaced-Apart.jpeg" alt="Doors Spaced Apart"/>
              <h5>Doors Spaced Apart</h5>
            </div>
            <div className='carousel'>
              <img src ="assets/images/Doors-Stacked-Right.jpeg" alt="Doors Stacked Right"/>
              <h5>Doors Stacked Right</h5>
            </div>
            <div className='carousel'>
              <img src ="assets/images/Easily-Replaced-Shelves-1.jpeg" alt="Easily Replaced Shelves 1"/>
              <h5>Easily Replaced Shelves with Drawers for Better Access and Increased Storage Density</h5>
            </div>
            <div className='carousel'>
              <img src ="assets/images/Easily-Replaced-Shelves-2.jpeg" alt="Easily Replaced Shelves 2"/>
              <h5>Easily Replaced Shelves with Drawers for Better Access and Increased Storage Density</h5>
            </div>
          </Slider>
        </div>
      </section>
    </div>
    </>
  );
}

export default Photos;
